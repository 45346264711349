export enum DBUtil {
  User = 'users',
  Guests = 'guests',

  EventItem = 'events',
  EventInsights = 'insights',
  EventJoiner = 'joiner',
  EventRatings = 'event_ratings',

  Conversation = 'conversations',
  UserGuests = 'userGuests',

  Team = 'teams',
  TeamPost = 'team_posts',
  TeamRoster = 'team_roster',
  TeamTopics = 'team_topics',
  TeamRoles = 'team_roles',
  TeamSurvey = 'surveys',
  TeamSurveyResult = 'results',
  TeamRatings = 'team_ratings',
  TeamSessions = 'team_sessions',
  TeamSeasons = 'team_seasons',
  TeamSeasonGroups = 'team_season_groups',
  Roster = 'roster',
  MemberRoles = 'member_roles',
  Reviews = 'reviews',

  GameTypes = 'game_types',
  Mailer = 'mail',
  Notification = 'notifications',
  Badge = 'badges',
  Places = 'places',
  PendingInvitations = 'pending_invitations',
  Invoices = 'invoices',
  Transactions = 'transactions',
  ExternalTeams = 'teams_external',
  AdminDebugging = 'user_roles',
  Connections = 'connections',
  Endorsements = 'endorsements',
  // temp properties below while developing
  Followers = 'followers',
  Following = 'following',
  Tournaments = 'tournaments',
  StripeSubscriptions = 'stripe_subscriptions',
  PersonalityTypes = 'personality_types',
  Genders = 'genders',

  // MEH Specific Tables
  Availability = 'availability',
  UserAvailability = 'user_availability',
  MehStripeSubscriptions = 'meh_stripe_subscriptions',
  GthStripeSubscriptions = 'gth_stripe_subscriptions',

  Shards = 'shards',

  Favorites = 'favorites',
  UserMetrics = 'user_metrics',
}

export const collectionRouteBuilder = (routeArray: (DBUtil | string)[]) => {
  const seperator = '/';
  return routeArray.join(seperator);
};
